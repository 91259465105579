/* src/styles/ClippyAssistant.css */
.clippy-assistant {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
}

.clippy-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
}

.clippy-image {
    width: 100px;
    height: auto;
    background-color: transparent;
    mix-blend-mode: multiply;
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
}

.bubble {
    position: absolute;
    width: 200px;
    padding: 10px;
    background: rgba(255, 255, 221, 0.9);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: center;
    z-index: 1001;
    right: 100%;
    bottom: 0;
    margin-right: 10px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    color: black;
}

.bubble::after {
    content: '';
    position: absolute;
    bottom: 20px;
    right: -10px;
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(255, 255, 221, 0.9);
}

/* Remove specific styles for expanded/non-expanded states */

.clippy-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: all 0.5s ease-in-out;
    z-index: 1000;
}

.clippy-image {
    width: 100px;
    height: auto;
}

.clippy-container.zoomIn {
    transform: scale(2);
    bottom: 50%;
    right: 50%;
}

.clippy-container.zoomOut {
    transform: scale(1);
}

.clippy-container.zoomIn .bubble {
    opacity: 1;
}

.bubble::after {
    content: '';
    position: absolute;
    right: -10px;
    bottom: 20px;
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(255, 255, 221, 0.9);
}

.clippy-assistant.zoomIn {
    transform: scale(2);
    transition: transform 0.5s ease-in-out;
}

.clippy-assistant.zoomOut {
    transform: scale(1);
    transition: transform 0.5s ease-in-out;
}
