/* Add this at the top of your globals.css file */
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Import Roboto font if not already present */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* eslint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
    padding: 0;
    background-image: url('../images/corkboard.png');
    background-repeat: repeat;
    background-size: 500px 500px; /* Adjust this value based on the actual size of your image */
    min-height: 100vh;
  }

  /* Apply Roboto font to all text elements */
  h1, h2, h3, h4, h5, h6, p, span, div, button, input, textarea, select {
    font-family: 'Roboto', sans-serif;
  }
}

@layer components {
  .custom-node {
    @apply border border-gray-300 p-4 rounded bg-white shadow-md;
    width: 200px; /* Adjust as needed */
  }

  .custom-node.expanded {
    @apply w-64; /* Wider when expanded */
  }

  .node-content {
    @apply flex flex-col items-center;
  }

  .image-container {
    @apply w-full h-32 overflow-hidden flex items-center justify-center;
    display: none; /* Hide by default */
  }

  .custom-node.expanded .image-container {
    display: flex; /* Show when expanded */
  }

  .node-image {
    @apply w-full h-full object-cover;
  }

  .node-label {
    @apply mt-2 text-sm text-center font-semibold;
  }

  .node-number {
    @apply absolute top-2 left-2 text-xs font-bold text-gray-500;
  }
}

.react-flow__node {
  width: auto !important;
  height: auto !important;
}

/* Adjust the background color of React Flow pane */
.react-flow__pane {
  background-color: transparent !important;
}

/* Style adjustments for better visibility on corkboard */
.custom-node {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
}

.react-flow__edge-path {
  stroke: #1b75e5 !important; /* Use brand color for edges */
  stroke-width: 2;
}

/* Add smooth transitions for resizing */
.custom-node, .image-container, .node-label, .student-journey, .earnings-counter, .clippy-image {
  transition: all 0.3s ease;
}

/* Add this to the end of your globals.css file */
@keyframes stickyNoteWiggle {
  0% { transform: rotate(-2deg); }
  50% { transform: rotate(2deg); }
  100% { transform: rotate(-2deg); }
}

.sticky-note {
  animation: stickyNoteWiggle 6s ease-in-out infinite;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Add this to your existing globals.css file */
.crisp-text {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
