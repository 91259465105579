@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.custom-node h2,
.node-details h2,
.markdown-content,
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  font-family: 'Roboto', Arial, sans-serif;
}

.custom-node h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #1a202c;
}

.node-details h2 {
  font-size: 1.2em;
  margin-top: 1em;
  color: #2d3748;
}

.node-details hr {
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #e2e8f0;
}

.node-details ul {
  list-style-type: disc;
  margin-left: 1.5em;
  margin-bottom: 1em;
}

.node-details p {
  margin-bottom: 2em;
}

@media (max-width: 600px) {
  .custom-node {
    width: 100%;
  }

  .custom-node h2,
  .node-details h2 {
    font-size: 1.2em;
  }

  .node-details {
    font-size: 1em;
  }
}

.markdown-content {
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.25;
  color: #333;
  font-size: 1.25rem; /* Adjust this value to match the previous font size */
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
  color: #1a202c;
}


.markdown-content h3 { font-size: 1.6em; }


.markdown-content p {
  margin-bottom: 1em;
}

.markdown-content strong {
  font-weight: bold;
}

.markdown-content ul,
.markdown-content ol {
  list-style-type: disc;
  padding-left: 2em;
  margin-bottom: 1em;
}

.markdown-content li {
  display: list-item;
  margin-bottom: 0.75em;
}

.markdown-content hr {
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #e2e8f0;
}

@media (max-width: 600px) {
  .markdown-content {
    font-size: 1rem; /* Adjust this value for mobile if needed */
  }

  .markdown-content h1 { font-size: 1.8em; }
  .markdown-content h2 { font-size: 1.4em; }
  .markdown-content h3 { font-size: 1.2em; }
  .markdown-content h4 { font-size: 1.1em; }
  .markdown-content h5,
  .markdown-content h6 { font-size: 1em; }
}
