.katex {
  font-family: 'Patrick Hand', cursive !important;
  font-size: 1.1em !important; /* Increased from 0.9em */
  font-weight: bold !important; /* Added bold font weight */
}

.katex .mfrac .frac-line {
  border-color: #333;
}

.katex .mord.text {
  font-family: 'Patrick Hand', cursive !important;
  font-weight: bold !important; /* Ensure text is bold */
}

/* Adjust the fraction size */
.katex .mfrac .frac-line {
  border-bottom-width: 1px;
}

.katex .mfrac .frac-line:after {
  border-bottom-width: 1px;
}

.katex .mfrac .frac-line {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.katex .mfrac .mfracnum,
.katex .mfrac .mfractiondenom {
  font-size: 1em !important; /* Increased from 0.85em */
}

/* Reduce overall equation size */
.katex-display {
  font-size: 1.1em; /* Increased from 0.85em */
}

/* Ensure long text doesn't get cut off */
.katex-display > .katex {
  white-space: normal;
}

.katex-display > .katex > .katex-html {
  white-space: normal;
}

.katex-display > .katex > .katex-html > .tag {
  position: relative;
}

/* Add this new rule to make sure inline text is also bold */
.katex .mord .text {
  font-weight: bold !important;
}